import { ReactComponent as ArrowForward } from 'assets/icons/circle-arrow-forward.svg';
import Button from 'components/common/Button';
import { get } from 'lodash';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Col, Row, Hidden } from 'react-grid-system';
import { formatValue } from 'utils/helpers';
import Table from 'rc-table';
import cn from 'classnames';
import { ReactComponent as EnergyScaleHouseImg } from 'assets/images/energy-scale-house.svg';
import useResizeObserver from '@react-hook/resize-observer';
import energyScaleClasses from './energy-scale-classes';
import s from './EnergyClassesAndIndicators.module.scss';
import { useTranslation } from 'react-i18next';
import { gtmButtons, trackButtonClick } from 'utils/analytics';
import { useParams } from 'react-router-dom';

function EnergyScaleClass({ label, color, isActualEnergyClass, isPotentialEnergyClass, index }) {
  const width = useMemo(() => `${60 + index * 15}px`, [index]);
  const backgroundColor = useMemo(
    () => (isActualEnergyClass || isPotentialEnergyClass ? color : ''),
    [color, isActualEnergyClass, isPotentialEnergyClass],
  );
  return (
    <div
      key={label}
      className={s.energyClass}
      style={{ width, ...(backgroundColor ? { backgroundColor, width: '90%' } : {}) }}
    >
      {label}
      <div className={s.triangle} style={backgroundColor ? { borderColor: backgroundColor } : {}} />
    </div>
  );
}

function ActualEnergyScaleClass({ isActualEnergyClass, isPotentialEnergyClass, t }) {
  if (!isActualEnergyClass && !isPotentialEnergyClass) return null;
  return (
    <div className={s.actualEnergyClass}>
      {isActualEnergyClass ? (
        <div className={s.label}>{t('co2CalculatorResult.energyScaleClasses.actualClass')}</div>
      ) : null}
      {isPotentialEnergyClass ? (
        <div className={s.label}>{t('co2CalculatorResult.energyScaleClasses.potentialClass')}</div>
      ) : null}
    </div>
  );
}

function getColumns() {
  return [
    {
      title: '',
      key: 'energyClassName',
      dataIndex: 'energyClassName',
      render: EnergyScaleClass,
    },
    {
      title: '',
      key: 'actualEnergyClass',
      dataIndex: 'actualEnergyClass',
      width: '25%',
      render: ActualEnergyScaleClass,
    },
  ];
}

const useSize = (target) => {
  const [size, setSize] = useState();

  useLayoutEffect(() => {
    if (target.current) {
      setSize(target.current.getBoundingClientRect());
    }
  }, [target]);

  useResizeObserver(target, (entry: any) => setSize(entry.contentRect));
  return size;
};

const EnergyScaleHouse = ({ containerTableRef }) => {
  const size = useSize(containerTableRef);

  const height = get(size, 'height', 0);
  const width = get(size, 'width', 0);

  return (
    <Hidden xs sm>
      <EnergyScaleHouseImg
        style={{
          height: `${height}px`,
          minHeight: `${height}px`,
          maxHeight: `${height}px`,
          width,
          marginRight: '10px',
        }}
      />
    </Hidden>
  );
};

export const EnergyScaleClasses = ({ actualEnergyClass, potentialEnergyClass, costOverviewTab = false }) => {
  const containerTableRef = useRef();
  const { t } = useTranslation();

  const columns = useMemo(() => getColumns(), []);

  const data = useMemo(
    () =>
      energyScaleClasses.map(({ label, color, index }) => {
        const isActualEnergyClass = index === actualEnergyClass.index;
        const isPotentialEnergyClass = index === potentialEnergyClass.index;
        return {
          key: label,
          energyClassName: { label, color, index, isActualEnergyClass, isPotentialEnergyClass },
          actualEnergyClass: { isActualEnergyClass, isPotentialEnergyClass, t },
        };
      }),
    [actualEnergyClass.index, potentialEnergyClass.index, t],
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
      <div ref={containerTableRef} style={{ width: '100%', order: 1 }}>
        <Table
          className={cn(s.energyScaleTable, !costOverviewTab ? s.withoutGrid : '')}
          direction="ltr"
          columns={columns}
          data={data}
          emptyText="No data"
        />
      </div>
      {!costOverviewTab ? <EnergyScaleHouse {...{ containerTableRef, t }} /> : null}
    </div>
  );
};

const HouseEnergyIndicators = ({ nextStep, calculations }) => {
  const { t } = useTranslation();
  const { projectId } = useParams();

  return (
    <div className={s.houseEnergyIndicators}>
      <h3 className={s.label}>{t('co2CalculatorResult.houseEnergyBalance.label')}</h3>
      <b className={s.heatingDemandValue}>
        {t('co2CalculatorResult.houseEnergyBalance.specificHeatingDemand', {
          value: formatValue(calculations.specific_heating_demand),
        })}
      </b>
      <div>
        <p>
          {t('co2CalculatorResult.houseEnergyBalance.heating', {
            value: formatValue(calculations.heating_ghg_emission),
          })}
        </p>
        <p>
          {t('co2CalculatorResult.houseEnergyBalance.hotWater', {
            value: formatValue(calculations.hot_water_ghg_emission),
          })}
        </p>
        <p>
          {t('co2CalculatorResult.houseEnergyBalance.electricity', {
            value: formatValue(calculations.ghg_balance_of_power_consumption),
          })}
        </p>
      </div>
      <div className={s.btnWrapper}>
        <Button
          id={gtmButtons.co2StatusStep.id}
          endIcon={<ArrowForward />}
          onClick={() => {
            trackButtonClick({ ...gtmButtons.co2StatusStep, value: projectId });
            nextStep();
          }}
        >
          {t('co2CalculatorResult.improveHouseBtnLabel')}
        </Button>
      </div>
    </div>
  );
};

const EnergyClassesAndIndicators = ({ co2Calculations, nextStep, actualEnergyClass, potentialEnergyClass }) => {
  const { co2_emissions_before } = co2Calculations;

  return (
    <Row align="center">
      <Col lg={8}>
        <EnergyScaleClasses {...{ actualEnergyClass, potentialEnergyClass }} />
      </Col>
      <Col lg={4}>
        <HouseEnergyIndicators {...{ nextStep, calculations: co2_emissions_before.result }} />
      </Col>
    </Row>
  );
};

export default EnergyClassesAndIndicators;
