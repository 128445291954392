import React from 'react';
import theme from 'config/theme';
import { useTranslation } from 'react-i18next';
import s from './BreakEvenPointChart.components.module.scss';

export const governmentFundingColor = '#FFA5A5';
export const equityColor = '#B3B8B9';
export const lineColor = '#51585F';
export const loanAmountColor = theme.primaryColor;

export const DotMarker = ({ color, id }: { color: string; id: string }) => (
  <marker id={id} viewBox="0 0 10 10" refX="5" refY="5" markerWidth="6" markerHeight="6">
    <circle cx="5" cy="5" r="5" fill={color} />
  </marker>
);

export const TriangleMarker = ({ color, id }: { color: string; id: string }) => (
  <marker id={id} viewBox="0 0 10 10" refX="10" refY="5" markerWidth="6" markerHeight="6" orient="auto-start-reverse">
    <path d="M 0 0 L 10 5 L 0 10 z" fill={color} />
  </marker>
);

const legendItems = [
  {
    labelKey: 'breakEvenPointTracker.chart.legend.governmentFunding',
    labelColor: '#242020',
    squareColor: governmentFundingColor,
  },
  {
    labelKey: 'breakEvenPointTracker.chart.legend.loanAmount',
    labelColor: loanAmountColor,
    squareColor: loanAmountColor,
  },
  {
    labelKey: 'breakEvenPointTracker.chart.legend.equity',
    labelColor: '#242020',
    squareColor: equityColor,
  },
];

export const Legend = () => {
  const { t } = useTranslation();
  return (
    <div className={s.legend}>
      {legendItems.map(({ labelColor, labelKey, squareColor }) => (
        <div key={labelKey} className={s.legendItem}>
          <div className={s.square} style={{ backgroundColor: squareColor }} />
          <div className={s.label} style={{ color: labelColor }}>
            {t(labelKey)}
          </div>
        </div>
      ))}
    </div>
  );
};

export function FinancingRepaid({ x, y, period }: { x: string; y: string; period: number }) {
  const { t } = useTranslation();

  return (
    <>
      <svg x={x} y={y} width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16.4355" cy="16" r="16" fill={loanAmountColor} />
        <g clipPath="url(#clip0_161_585)">
          <path
            d="M18.5442 7.2085L17.6442 8.1085L18.9775 9.44183C19.1609 9.65016 19.2525 9.89183 19.2525 10.1668C19.2525 10.4418 19.1609 10.7002 18.9775 10.8835L16.0192 13.8918L16.8525 14.7918L19.8775 11.7835C20.3192 11.2918 20.5359 10.7502 20.5359 10.1668C20.5359 9.5835 20.3192 9.0335 19.8775 8.54183L18.5442 7.2085ZM15.2275 8.89183L14.3275 9.79183L14.8359 10.2585C15.0192 10.4418 15.1109 10.6918 15.1109 11.0002C15.1109 11.3085 15.0192 11.5585 14.8359 11.7418L14.3275 12.2085L15.2275 13.1085L15.6942 12.6002C16.1359 12.1085 16.3609 11.5752 16.3609 11.0002C16.3609 10.4002 16.1359 9.8585 15.6942 9.3585L15.2275 8.89183ZM23.9359 10.2168C23.3609 10.2168 22.8275 10.4418 22.3359 10.8835L17.6442 15.5835L18.5442 16.4168L23.1942 11.7835C23.4025 11.5752 23.6525 11.4668 23.9359 11.4668C24.2192 11.4668 24.4692 11.5752 24.6775 11.7835L25.1859 12.2918L26.0442 11.3918L25.5775 10.8835C25.0859 10.4418 24.5359 10.2168 23.9359 10.2168ZM12.2692 12.6668L8.10254 24.3335L19.7692 20.1668L12.2692 12.6668ZM22.2692 15.2168C21.6859 15.2168 21.1525 15.4418 20.6525 15.8835L19.3275 17.2085L20.2275 18.1085L21.5525 16.7835C21.7609 16.5752 21.9942 16.4668 22.2692 16.4668C22.5442 16.4668 22.7942 16.5752 23.0025 16.7835L24.3525 18.1085L25.2275 17.2502L23.8942 15.8835C23.4025 15.4418 22.8525 15.2168 22.2692 15.2168Z"
            fill="white"
          />
        </g>
      </svg>
      <text x={x} y={`${Number(y) + 50}`} fill={loanAmountColor} style={{ fontWeight: 'bold' }}>
        {period} Jahre
      </text>
      <text x={x} y={`${Number(y) + 70}`} fill={loanAmountColor}>
        {t('breakEvenPointTracker.chart.financingRepaid.firstPart')}
      </text>
      <text x={x} y={`${Number(y) + 90}`} fill={loanAmountColor}>
        {t('breakEvenPointTracker.chart.financingRepaid.secondPart')}
      </text>
    </>
  );
}

export function ProfitStartPoint({ x, y, period }: { x: string; y: string; period: number }) {
  const { t } = useTranslation();

  return (
    <>
      <svg x={x} y={y} width="33" height="32" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.5 0C9.64105 0 0 9.64105 0 21.5C0 33.3589 9.64105 43 21.5 43C33.3589 43 43 33.3589 43 21.5C43 9.64105 33.3589 0 21.5 0ZM23.4916 32.3179H23.3105H23.1747C21.8168 32.2727 20.5948 31.9557 19.5084 31.4126C18.3316 30.8241 17.3357 30.0547 16.5211 28.9684C15.8421 28.1083 15.3443 27.0674 14.9821 25.9811H12.1758V22.5411H14.3031C14.3031 22.1789 14.2579 21.8621 14.2579 21.5C14.2579 21.0926 14.3031 20.7306 14.3031 20.3232H12.1304V16.9284H14.9367C15.2988 15.7968 15.8419 14.8464 16.4757 13.9863C17.2905 12.9452 18.2862 12.0853 19.463 11.5421C20.5493 10.999 21.7714 10.682 23.1293 10.682H23.4461C25.2114 10.7273 26.886 11.2251 28.3797 12.0851L26.7955 15.2536C25.7544 14.5746 24.5776 14.2124 23.2649 14.122C21.5448 14.1672 19.7797 14.982 18.7386 16.9282H24.0344V20.323H17.7881C17.7428 20.7304 17.7428 21.0924 17.7428 21.4998C17.7428 21.8619 17.7881 22.2239 17.7881 22.5409H24.0344V25.9809H18.6481C19.6892 27.9725 21.4997 28.7871 23.2197 28.8325C24.4871 28.7873 25.7091 28.4251 26.7503 27.7462L28.3345 30.8694C26.9769 31.7746 25.257 32.2726 23.4917 32.3179L23.4916 32.3179Z"
          fill={loanAmountColor}
        />
      </svg>

      <text x={x} y={`${Number(y) + 50}`} fill={loanAmountColor} style={{ fontWeight: 'bold' }}>
        {period} Jahre
      </text>
      <text x={x} y={`${Number(y) + 70}`} fill={loanAmountColor}>
        {t('breakEvenPointTracker.chart.profitStartPoint.firstPart')}
      </text>
      <text x={x} y={`${Number(y) + 90}`} fill={loanAmountColor}>
        {t('breakEvenPointTracker.chart.profitStartPoint.secondPart')}
      </text>
    </>
  );
}
