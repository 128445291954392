/* eslint-disable no-undef */
import React, { useCallback, useState } from 'react';
import { ReactComponent as ArrowForward } from 'assets/icons/circle-arrow-forward.svg';
import PageTitle from 'components/common/PageTitle';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'components/common/Button';
import houseImg from 'assets/images/home-screen.png';
import s from './CreateProject.module.scss';
import { FastField, Form, Formik } from 'formik';
import Checkbox from 'components/inputs/Checkbox';
import * as yup from 'yup';
import useCreateProject from 'hooks/project/useCreateProject';
import { ImageContainer } from 'components/common/ImageContainer';
import { Col, Row } from 'react-grid-system';
import config from 'config/config';
import TagManager from 'react-gtm-module';
import { gtmButtons, gtmPages, gtmLinks, useTrackPageView, trackLinkClick } from 'utils/analytics';

const validationSchema = yup.object().shape({
  termsOfUseConsent: yup.bool().oneOf([true], 'errors.termsOfUseRequired'),
});

const CreateProject = () => {
  const { t } = useTranslation();
  const initialValues = { termsOfUse: false };
  const onSubmit = useCreateProject();
  const [gtmInitialized, setGTMInitialized] = useState(false);
  const trackPage = useTrackPageView({ autoTrack: false, ...gtmPages.newProject });

  const onAcceptTermsOfUse = useCallback(() => {
    if (!gtmInitialized) {
      TagManager.initialize({ gtmId: config.GTMId });
      setGTMInitialized(true);
      trackPage();
    }
  }, [gtmInitialized, trackPage]);

  return (
    <div className={s.container}>
      <div className={s.inner}>
        <Row align="start" justify="center">
          <Col xs={12} md={10} lg={9} xl={8}>
            <PageTitle className="text-center text-primary">{t('pageTitles.createProject')}</PageTitle>
            <ImageContainer
              src={houseImg}
              alt="House"
              width="100%"
              containerClassnames={s.imgContainer}
              imgClassnames={s.img}
            />
            <Formik {...{ initialValues, onSubmit, validationSchema }}>
              {({ isSubmitting }) => (
                <Form className={s.form}>
                  <div className={s.termsOfUseConsent}>
                    <FastField
                      name="termsOfUseConsent"
                      component={Checkbox}
                      skipScrollingOnChange
                      sideEffectOnChange={onAcceptTermsOfUse}
                      label={
                        <Trans
                          i18nKey="auth.termsOfUseLabel"
                          components={{
                            termsOfUseUrl: (
                              <a
                                id={gtmLinks.termsOfUse.id}
                                href={config.termsOfUseUrl}
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{ textDecoration: 'underline', color: '#0086cd' }}
                              >
                                {t('auth.termsOfUseLinkText')}
                              </a>
                            ),
                          }}
                        />
                      }
                    />
                  </div>
                  <div className={s.btnWrapper}>
                    <Button
                      id={gtmButtons.createProject.id}
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      type="submit"
                      endIcon={<ArrowForward />}
                    >
                      {t('button.start')}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CreateProject;
