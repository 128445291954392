import React, { useState, useRef } from 'react';
import { formatCurrency, formatValue } from 'utils/helpers';
import cn from 'classnames';
import pick from 'lodash/pick';
import { Small } from 'components/common/heap';
import CircleButton from 'components/common/CircleButton';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as Pencil } from 'assets/icons/pencil.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tick.svg';
import PlainNumberInput from 'components/inputs/PlainNumberInput';
import s from '../CalculatorResultOverview.module.scss';
import { useTranslation } from 'react-i18next';

const HotWaterResult = ({ co2Calculations, onSaveButtonClick }) => {
  const { t } = useTranslation();
  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef<any>();

  const whiteListValues = [
    'primary_energy_for_hot_water',
    'hot_water_ghg_emission',
    'hot_water_ghg_equivalent_car',
    'hot_water_ghg_equivalent_plane',
    'hot_water_ghg_equivalent_trees',
    'total_hot_water_costs',
  ];

  const hotWaterResultValues: any = Object.entries(
    pick(co2Calculations.co2_emissions_before.result, whiteListValues),
  ).reduce((acc, [key, value]) => {
    if (key === 'primary_energy_for_hot_water') {
      const tmpValue = Number.isFinite(value.user_input) ? value.user_input : value.calculated_value;
      // const parsedValue = parseFloat(tmpValue).toFixed(3) * 1; // TODO: fix
      const parsedValue = parseFloat(tmpValue);
      return { ...acc, [key]: parsedValue };
    }
    // const parsedValue = parseFloat(tmpValue).toFixed(3) * 1; // TODO: fix
    return { ...acc, [key]: parseFloat(value) };
  }, {});

  return (
    <div style={{ overflowX: 'scroll' }}>
      <table className={cn('table before-last-col-text-right grey-first', s.resultTable)}>
        <thead>
          <tr>
            <th colSpan={2}>
              <h4>{t('co2CalculatorResult.hotWaterResult.tableTitle')}</h4>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {t('co2CalculatorResult.hotWaterResult.primaryEnergyForHotWater')}
              <Small>{t('co2CalculatorResult.hotWaterResult.primaryEnergyForHotWaterDescription')}</Small>
            </td>
            <td>
              {showInput ? (
                <PlainNumberInput
                  name="primary_energy_for_heating"
                  ref={inputRef}
                  defaultValue={hotWaterResultValues.primary_energy_for_hot_water}
                />
              ) : (
                <span>{formatValue(hotWaterResultValues.primary_energy_for_hot_water)}kWh/a</span>
              )}
            </td>
            <td>
              {showInput ? (
                <div className={s.buttonsWrapper}>
                  <CircleButton onClick={() => setShowInput(false)}>
                    <Close />
                  </CircleButton>
                  <CircleButton
                    onClick={() =>
                      onSaveButtonClick({
                        callback: () => setShowInput(false),
                        fieldName: 'primary_energy_for_hot_water',
                        fieldValue: Number(inputRef.current.state.numAsString),
                      })
                    }
                  >
                    <TickIcon />
                  </CircleButton>
                </div>
              ) : (
                <CircleButton onClick={() => setShowInput(true)}>
                  <Pencil />
                </CircleButton>
              )}
            </td>
          </tr>
          <tr>
            <td>
              {t('co2CalculatorResult.hotWaterResult.hotWaterGhgEmission')}
              <Small>{t('co2CalculatorResult.hotWaterResult.hotWaterGhgEmissionDescription')}</Small>
            </td>
            <td>{formatValue(hotWaterResultValues.hot_water_ghg_emission)} kg/a</td>
            <td />
          </tr>
          {/* <tr>
            <td>
              {t('co2CalculatorResult.hotWaterResult.hotWaterGhgEquivalentCar')}
              <Small>{t('co2CalculatorResult.hotWaterResult.hotWaterGhgEquivalentCarDescription')}</Small>
            </td>
            <td>{formatValue(hotWaterResultValues.hot_water_ghg_equivalent_car)} km</td>
            <td />
          </tr>
          <tr>
            <td>
              {t('co2CalculatorResult.hotWaterResult.hotWaterGhgEquivalentPlane')}
              <Small>{t('co2CalculatorResult.hotWaterResult.hotWaterGhgEquivalentPlaneDescription')}</Small>
            </td>
            <td>
              {formatValue(hotWaterResultValues.hot_water_ghg_equivalent_plane)}
              <Small>{t('co2CalculatorResult.numberOfEconomyClassFlights')}</Small>
            </td>
            <td />
          </tr>
          <tr>
            <td>
              {t('co2CalculatorResult.hotWaterResult.hotWaterGhgEquivalentTrees')}
              <Small>{t('co2CalculatorResult.hotWaterResult.hotWaterGhgEquivalentTreesDescription')}</Small>
            </td>
            <td>
              {formatValue(hotWaterResultValues.hot_water_ghg_equivalent_trees)}
              <Small>{t('co2CalculatorResult.numberStoredTrees')}</Small>
            </td>
            <td />
          </tr> */}
          <tr>
            <td>
              {t('co2CalculatorResult.hotWaterResult.hotWaterCosts')}
              <Small>{t('co2CalculatorResult.hotWaterResult.hotWaterCostsDescription')}</Small>
            </td>
            <td>{formatCurrency(hotWaterResultValues.total_hot_water_costs)}</td>
            <td />
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default HotWaterResult;
