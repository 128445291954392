import { useParams } from 'react-router-dom';
import React, { useLayoutEffect } from 'react';
import Loading from 'components/common/Loading';
import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import WarningMessage from 'components/common/WarningMessage/WarningMessage';
import Button from 'components/common/Button';
import CO2StatusProjectPlanSection from 'components/CalculatorResultOverview/CO2StatusProjectPlanSection';
import useProjectOverview from 'hooks/projectPlan/useProjectOverview';
import ProjectPlanTables from 'components/ProjectOverview/ProjectPlanTables';
import { ReactComponent as Pencil } from 'assets/icons/pencil.svg';
import { formatGraphqlErrors } from 'utils/helpers';
import GoHomeAlert from 'components/ProjectOverview/GoHomeAlert';
import BeforeAndAfterOverview from './BeforeAndAfterTable/BeforeAndAfterTable';
import GetAppointmentSection from './GetAppointmentSection/GetAppointmentSection';
import TotalSavingsPotentialSection from './TotalSavingsPotentialSection/TotalSavingsPotentialSection';
import EnergyGhgEquivalents from 'components/CalculatorResultOverview/EnergyGhgEquivalents';
import { FinalStepContextProvider, useFinalStepContext } from './FinalStepContext';
import RenovationPerspectiveSection from './RenovationPerspectiveSection/RenovationPerspectiveSection';
import s from './CalculatorFinalTab.module.scss';
import BreakEvenPointTrackerSection from '../BreakEvenPointTracker/BreakEvenPointTrackerSection';
import theme from 'config/theme';
import FortyTwoWattSection from './FortyTwoWattSection/FortyTwoWattSection';
import DBCPartnerExtraSection from './DBC/DBCPartnerExtraSection';
import DBCPartnerSection from './DBC/DBCPartnerSection';
import { gtmPages, useTrackPageView } from 'utils/analytics';

const Header = () => {
  const { t } = useTranslation();
  const { project, goToStep } = useFinalStepContext();

  return (
    <>
      <h1 className={s.projectTitle}>{t('plan.title', { projectName: project.name })}</h1>

      <div className={s.header}>
        <h2>{t('pageTitles.plan')}</h2>
        <Button onClick={() => goToStep(1)} startIcon={<Pencil />} color="monochrome">
          {t('plan.backToCalculator')}
        </Button>
      </div>
    </>
  );
};

const CalculatorFinalTab = () => {
  useLayoutEffect(() => {
    document.body.style.backgroundColor = theme.backgroundColor;
    return () => {
      document.body.style.backgroundColor = 'white';
    };
  }, []);

  return (
    <>
      {/* <PdfCTASection /> */}
      <Header />
      <CO2StatusProjectPlanSection />
      <section className="position-relative">
        <div className={s.whiteBg} />
        {/* <TotalSavingsPotentialSection /> */}
        <DBCPartnerSection />
        {/* <PriceHubbleSection {...{ formValuesRef }} /> */}
      </section>
      <EnergyGhgEquivalents {...{ costOverviewTab: true }} />
      <div style={{ paddingTop: '36px' }}>
        <ProjectPlanTables />
      </div>
      {/* <BeforeAndAfterOverview /> */}
      <BreakEvenPointTrackerSection />
      <section className="position-relative">
        <div className={s.whiteBg} style={{ marginTop: '24px' }} />
        {/* <RenovationPerspectiveSection />
        <GetAppointmentSection /> */}
        <DBCPartnerExtraSection />
      </section>
      <FortyTwoWattSection />
    </>
  );
};

const CalculatorFinalTabContainer = ({ goToStep, formValuesRef }) => {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const { projectPlan, project, co2Calculations, loading, error } = useProjectOverview(projectId);
  useTrackPageView(gtmPages.result);

  if (loading) return <Loading size={50} />;
  if (error) return <GoHomeAlert message={formatGraphqlErrors(error).message} />;
  if (!project) return <GoHomeAlert message={t('errors.projectNotFound')} />;

  if (projectPlan?.price === 0) {
    return (
      <Row justify="center">
        <Col md={8} lg={6}>
          <WarningMessage
            title={t('pageTitles.plan')}
            message={t('plan.completePreviousStep')}
            btnLabel={t('plan.completePreviousStepBtn')}
            onBtnClick={() => goToStep(4)}
          />
        </Col>
      </Row>
    );
  }

  return (
    <FinalStepContextProvider
      value={{
        percentageCostsReduction: 0,
        projectId: project._id,
        project,
        projectPlan,
        co2Calculations,
        energyLabels: { before: { label: 'E', color: '#F0C143' }, after: { label: 'C', color: '#CDEC4D' } },
        formValuesRef,
        goToStep,
      }}
    >
      <CalculatorFinalTab />
    </FinalStepContextProvider>
  );
};

export default CalculatorFinalTabContainer;
