import React from 'react';
import dbc from 'assets/images/dbc.png';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import s from './DBCPartnerLogo.module.scss';

const DBCPartnerLogo = ({ leftRounded = false, rightRounded = false }) => {
  const { t } = useTranslation();

  return (
    <div className={cn(s.logo, { [s.leftRounded]: leftRounded, [s.rightRounded]: rightRounded })}>
      <span className={s.text}>{t('plan.ourPartner')}</span>
      <img src={dbc} />
    </div>
  );
};

export default DBCPartnerLogo;
