import Button from 'components/common/Button/Button';
import config from 'config/config';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WebIcon } from 'assets/icons/web.svg';
import fortyTwoWattLogo from 'assets/images/42watt.png';
import fortyTwoWattBg from 'assets/images/42watt-bg.png';
import s from './FortyTwoWattSection.module.scss';
import { gtmLinks, trackLinkClick } from 'utils/analytics';

const FortyTwoWattSection = () => {
  const { t } = useTranslation();

  return (
    <Row align="center" className={s.row}>
      <Col md={6} className={s.col}>
        <div className={s.imagesWrapper}>
          <img src={fortyTwoWattBg} className={s.bg} />
          <img src={fortyTwoWattLogo} className={s.logo} />
        </div>
      </Col>
      <Col md={6} className={s.col}>
        <h2 className="section-heating">{t('fortyTwoWattSection.title')}</h2>
        <p className="paragraph">{t('fortyTwoWattSection.description')}</p>
        <a
          id={gtmLinks.fortyTwoWatt.id}
          href={config.fortyTwoWebsiteUrl}
          onClick={() => trackLinkClick(gtmLinks.fortyTwoWatt)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Button color="outline" endIcon={<WebIcon />}>
            {t('fortyTwoWattSection.btnLabel')}
          </Button>
        </a>
      </Col>
    </Row>
  );
};

export default FortyTwoWattSection;
