/* eslint-disable no-nested-ternary */

const SelectCustomStyles = {
  container: (style) => ({
    ...style,
    outline: 'none',
    border: '1px solid black',
    borderRadius: '6px',
  }),
  control: (style) => ({
    ...style,
    border: 'none',
    outline: 'none',
    borderRadius: '6px',
    backgroundColor: 'white',
    width: '100%',
    padding: '5px 10px 5px 15px',
    boxShadow: 'none',
    '&:hover': {
      outline: 'none',
    },
  }),
  dropdownIndicator: (style) => ({
    ...style,
    color: 'black',
  }),
  valueContainer: (style) => ({
    ...style,
    padding: 0,
    height: '30px',
  }),
  singleValue: (style) => ({
    ...style,
    fontSize: '1rem',
    lineHeight: '1.25rem',
    color: 'black',
    margin: 0,
  }),
  option: (style, { isFocused, isSelected }) => ({
    ...style,
    backgroundColor: isSelected ? '#ff0000' : isFocused ? '#ffe6e6' : null,
    color: isSelected ? 'white' : 'black',
    textAlign: 'left',
  }),
};

const featuresInputStyle = {
  display: 'inlineBlock',
  width: '90%',
};

export { featuresInputStyle, SelectCustomStyles };
