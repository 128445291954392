import React, { useCallback } from 'react';
import { Col, Row } from 'react-grid-system';
import { Formik, Form } from 'formik';
import ScrollToError from 'components/common/ScrollToError';
import Button from 'components/common/Button';
import { ReactComponent as ArrowForward } from 'assets/icons/circle-arrow-forward.svg';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import firstStepImg from 'assets/images/first-step.png';
import { useParams } from 'react-router-dom';
import { useUpdateCO2CalculatorValuesHandler } from 'hooks/calculator/useUpdateCO2CalculatorValues';
import ScrollToTop from 'components/common/ScrollToTop';
import validationSchema from './yupSchemas/firstStepSchema';
import { FirstSection, SecondSection, FourthSection, ThirdSection } from './FirstTabSections';
import s from './calculator.module.scss';
import { useTranslation } from 'react-i18next';
import { ImageContainer } from 'components/common/ImageContainer';
import { gtmButtons, gtmPages, trackButtonClick, useTrackPageView } from 'utils/analytics';

const CO2CalculatorFormFirstStep = ({
  nextStep,
  initialValues,
  onSubmitStep,
  formValuesRef,
  isEditMode,
  currentStep,
  goToStep,
}: any) => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const onSubmit = useUpdateCO2CalculatorValuesHandler({ projectId });
  useTrackPageView(gtmPages.objectDataForm);

  const goToThirdStep = useCallback(() => {
    goToStep(3);
  }, [goToStep]);

  return (
    <Row justify="center">
      <ScrollToTop />
      <Col md={8} lg={6} order={{ xs: 1 }}>
        <Formik
          initialValues={formValuesRef?.current || initialValues}
          onSubmit={async (values, formikActions) => {
            const step = isEditMode ? null : currentStep + 1;
            const { renovations } = values;
            const newValues = { ...values, renovations: renovations.includes('none') ? [] : renovations, step };
            await onSubmit(newValues, formikActions);
            trackButtonClick({ ...gtmButtons.objectDataStep, value: projectId });
            if (typeof onSubmitStep === 'function') onSubmitStep(values);
            if (typeof nextStep === 'function') nextStep();
          }}
          validationSchema={validationSchema}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <ScrollToError />
              <FirstSection {...{ values, initialValues, t, isEditMode, goToThirdStep }} />
              <SecondSection {...{ values, t }} />
              <ThirdSection t={t} />
              <FourthSection {...{ t, values }} />

              <div className={s.btnWrapper}>
                <Button
                  id={gtmButtons.objectDataStep.id}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
                  className="ml-auto"
                >
                  {t(`button.${isEditMode ? 'save' : 'next'}`)}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
      <Col md={8} lg={6} order={{ lg: 1 }}>
        <div className={s.stickyBox}>
          <ImageContainer src={firstStepImg} alt="House" width="80%" />
        </div>
      </Col>
    </Row>
  );
};

export default CO2CalculatorFormFirstStep;
