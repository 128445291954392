import { FastField, Form, Formik, FormikHelpers } from 'formik';
import React, { useCallback } from 'react';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import Button from 'components/common/Button';
import useRequestProjectPDF from 'hooks/projectPlan/useRequestProjectPDF';
import TextInput from 'components/inputs/TextInput';
import config from 'config/config';
import { useFinalStepContext } from '../FinalStepContext';
import s from './RequestPdfForm.module.scss';
import { Col, Row } from 'react-grid-system';
import Select from 'components/inputs/Select/Select';
import dbcLogo from 'assets/images/dbc.png';
import { gtmButtons, gtmLinks, trackButtonClick, trackLinkClick } from 'utils/analytics';

interface IRequestPdfForm {
  hideModal: () => void;
}

const validationSchema = yup.object().shape({
  firstName: yup.string().required('errors.fillField'),
  lastName: yup.string().required('errors.fillField'),
  email: yup.string().email('errors.invalidEmail').required('errors.fillField'),
  phone_number: yup.string().required('errors.fillField'),
  project_implementation_start_point: yup.string().required('errors.chooseOne').nullable(),
});

interface IFormValues {
  email: string;
  firstName: string;
  lastName: string;
  phone_number: string;
  project_implementation_start_point: string;
}

const initialValues: IFormValues = {
  email: '',
  firstName: '',
  lastName: '',
  phone_number: '',
  project_implementation_start_point: '',
};

const RequestPdfForm: FC<IRequestPdfForm> = ({ hideModal }) => {
  const { t } = useTranslation();
  const { projectId } = useFinalStepContext();
  const requestPDF = useRequestProjectPDF(hideModal);

  const onSubmit = useCallback(
    async (values: IFormValues, { setSubmitting }: FormikHelpers<IFormValues>) => {
      await requestPDF({ input: values, projectId });
      trackButtonClick({ ...gtmButtons.requestPdfModalBtn, value: projectId });
      setSubmitting(false);
    },
    [requestPDF, projectId],
  );

  return (
    <Formik {...{ initialValues, validationSchema, onSubmit }}>
      {({ isSubmitting }) => (
        <Form>
          <Row>
            <Col md={6}>
              <FastField name="firstName" component={TextInput} label={t('user.firstName')} />
            </Col>
            <Col md={6}>
              <FastField name="lastName" component={TextInput} label={t('user.lastName')} />
            </Col>
          </Row>
          <FastField name="email" component={TextInput} type="email" label={t('auth.email')} />
          <FastField name="phone_number" component={TextInput} label={t('auth.phoneNumber')} />
          <FastField
            name="project_implementation_start_point"
            component={Select}
            label={t('requestPdfModal.projectImplementationStartPoint.label')}
            options={[
              {
                label: t('requestPdfModal.projectImplementationStartPoint.options.withinThreeMonths'),
                value: 'within_three_months',
              },
              {
                label: t('requestPdfModal.projectImplementationStartPoint.options.inThreeToSixMonths'),
                value: 'in_three_to_six_months',
              },
              {
                label: t('requestPdfModal.projectImplementationStartPoint.options.laterThanInSixMonths'),
                value: 'later_than_in_six_months',
              },
            ]}
          />

          <p className={s.confirmationText}>
            <Trans
              i18nKey="requestPdfModal.confirmationText"
              components={{
                br: <br />,
                a: (
                  <a
                    id={gtmLinks.privacyPolicy.id}
                    href={config.privacyPolicyUrl}
                    onClick={() => trackLinkClick(gtmLinks.privacyPolicy)}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-black"
                  />
                ),
              }}
            />
          </p>

          <Row align="center">
            <Col md={6} order={{ xs: 2, md: 1 }}>
              <Button
                id={gtmButtons.requestPdfModalBtn.id}
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
                className={s.submitButton}
              >
                {t('requestPdfModal.submitFormBtn')}
                <Download className="ml-1" color="white" />
              </Button>
            </Col>
            <Col md={6} order={{ xs: 1, md: 2 }}>
              <div className={s.ourPartner}>
                <p className={s.text}>{t('plan.ourPartner')}</p>
                <img src={dbcLogo} className={s.logo} />
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default RequestPdfForm;
