import React, { useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import TextInput from 'components/inputs/TextInput';
import Button from 'components/common/Button';
import ScrollToError from 'components/common/ScrollToError';
import { Col, Row } from 'react-grid-system';
import { ReactComponent as ArrowBack } from 'assets/icons/angle-back.svg';
import { ReactComponent as ArrowForward } from 'assets/icons/circle-arrow-forward.svg';
import { ReactComponent as Floor1 } from 'assets/icons/calculator/floors/floor-1.svg';
import { ReactComponent as Floor2 } from 'assets/icons/calculator/floors/floor-2.svg';
import { ReactComponent as Floor3 } from 'assets/icons/calculator/floors/floor-3.svg';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import fourthStepImg from 'assets/images/fourth-step.png';
import SliderWithTooltip from 'components/inputs/SliderWithTooltip';
import ButtonSelect from 'components/inputs/ButtonSelect';
import validationSchema from './yupSchemas/fourthStepSchema';
import s from './calculator.module.scss';
import { useTranslation } from 'react-i18next';
import { ImageContainer } from 'components/common/ImageContainer';
import LocationSearchInput from 'components/inputs/GoogleMap/LocationSearchInput';
import MapContainer from 'components/inputs/GoogleMap/MapContainer';
import { gtmButtons, gtmPages, trackButtonClick, useTrackPageView } from 'utils/analytics';
import { useParams } from 'react-router-dom';

const CalculatorFourthTab = ({
  onSubmitStep,
  lastStep,
  onSubmit,
  isEditMode,
  previousStep,
  nextStep,
  initialValues,
  formValuesRef,
  currentStep,
  onBack,
}) => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  useTrackPageView(gtmPages.generalInfoForm);

  const validateHouseAreaInput = useCallback(
    (houseArea) => {
      if (isEditMode) {
        if (houseArea < initialValues?.heated_living_area) {
          return t('project.wrong_min_house_area', { value: initialValues.heated_living_area });
        }
      } else if (houseArea < formValuesRef?.current?.heated_living_area) {
        return t('project.wrong_min_house_area', { value: formValuesRef.current.heated_living_area });
      }
      return null;
    },
    [formValuesRef, initialValues, isEditMode, t],
  );

  return (
    <Formik
      initialValues={formValuesRef?.current}
      onSubmit={async (values, formikActions) => {
        onSubmitStep(values);
        trackButtonClick({ ...gtmButtons.generalInfoStep, value: projectId });
        await onSubmit({ values, formikActions, currentStep, isEditMode, lastStep, nextStep });
      }}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, values }) => (
        <Row justify="center" className={s.fourthStep}>
          <Col md={8} lg={6} order={{ xs: 1 }}>
            <Form className={s.form}>
              <ScrollToError formik={undefined} />
              <Field name="name" component={TextInput} type="text" label={t('project.project_name')} />
              <Field
                name="full_address"
                latLngName="coordinates"
                component={LocationSearchInput}
                label={t('project.full_address')}
              />
              <Field
                name="house_area"
                units="m²"
                component={SliderWithTooltip}
                validate={validateHouseAreaInput}
                min={formValuesRef?.current?.heated_living_area || initialValues?.heated_living_area || 0}
                max={300}
                step={1}
                defaultValue={formValuesRef.current.heated_living_area}
                label={t('project.house_area')}
              />
              <Field
                name="floors_number"
                component={ButtonSelect}
                nullable={false}
                inline
                options={[
                  {
                    icon: <Floor1 />,
                    label: 1,
                    value: 1,
                  },
                  {
                    icon: <Floor2 />,
                    label: 2,
                    value: 2,
                  },
                  {
                    icon: <Floor3 />,
                    label: 3,
                    value: 3,
                  },
                ]}
                label="project.floors"
              />

              <div className={s.buttonsWrapper}>
                {!isEditMode ? (
                  <Button
                    disabled={isSubmitting}
                    onClick={() => onBack(values, previousStep)}
                    startIcon={<ArrowBack />}
                    color="outline"
                  >
                    {t('button.back')}
                  </Button>
                ) : null}
                <Button
                  id={gtmButtons.generalInfoStep.id}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
                  className="ml-auto"
                >
                  {t(`button.${isEditMode ? 'save' : 'next'}`)}
                </Button>
              </div>
            </Form>
          </Col>
          <Col md={8} lg={6} order={{ lg: 1 }}>
            {values.coordinates ? (
              <MapContainer position={values.coordinates} />
            ) : (
              <div className={s.stickyBox}>
                <ImageContainer src={fourthStepImg} alt="tablet" width="70%" />
              </div>
            )}
          </Col>
        </Row>
      )}
    </Formik>
  );
};

export default CalculatorFourthTab;
