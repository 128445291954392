import React, { useState, useRef } from 'react';
import { formatCurrency, formatValue } from 'utils/helpers';
import cn from 'classnames';
import pick from 'lodash/pick';
import { Small } from 'components/common/heap';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as Pencil } from 'assets/icons/pencil.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tick.svg';
import CircleButton from 'components/common/CircleButton';
import PlainNumberInput from 'components/inputs/PlainNumberInput';
import s from '../CalculatorResultOverview.module.scss';
import { useTranslation } from 'react-i18next';

const HeatingResult = ({ co2Calculations, onSaveButtonClick }) => {
  const { t } = useTranslation();
  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef<any>();

  const whiteListValues = [
    'specific_heating_demand',
    'heating_factor_kea',
    'heating_factor_ghg',
    'total_heating_demand',
    'primary_energy_for_heating',
    'heating_ghg_emission',
    'heating_ghg_equivalent_car',
    'heating_ghg_equivalent_plane',
    'heating_ghg_equivalent_trees',
    'total_heating_costs',
  ];

  const heatingResultValues: any = Object.entries(
    pick(co2Calculations.co2_emissions_before.result, whiteListValues),
  ).reduce((acc, [key, value]) => {
    if (key === 'primary_energy_for_heating') {
      const tmpValue = Number.isFinite(value.user_input) ? value.user_input : value.calculated_value;
      // const parsedValue = parseFloat(tmpValue).toFixed(3) * 1; // TODO: fix
      const parsedValue = parseFloat(tmpValue);
      return { ...acc, [key]: parsedValue };
    }
    // const parsedValue = parseFloat(tmpValue).toFixed(3) * 1; // TODO: fix
    return { ...acc, [key]: parseFloat(value) };
  }, {});

  return (
    <div style={{ overflowX: 'scroll' }}>
      <table className={cn('table before-last-col-text-right grey-first', s.resultTable)}>
        <thead>
          <tr>
            <th colSpan={2}>
              <h4>{t('co2CalculatorResult.heatingResult.tableTitle')}</h4>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {t('co2CalculatorResult.heatingResult.specificHeatingDemand')}
              <Small>{t('co2CalculatorResult.heatingResult.specificHeatingDemandDescription')}</Small>
            </td>
            <td>{formatValue(heatingResultValues.specific_heating_demand)} kWh/m²</td>
            <td />
          </tr>
          <tr>
            <td>
              {t('co2CalculatorResult.heatingResult.heatingFactorKea')}
              <Small>{t('co2CalculatorResult.heatingResult.heatingFactorKeaDescription')}</Small>
            </td>
            <td>{formatValue(heatingResultValues.heating_factor_kea)} kWh/kWh</td>
            <td />
          </tr>
          <tr>
            <td>
              {t('co2CalculatorResult.heatingResult.heatingFactorGhg')}
              <Small>{t('co2CalculatorResult.heatingResult.heatingFactorGhgDescription')}</Small>
            </td>
            <td>{formatValue(heatingResultValues.heating_factor_ghg)} kg CO2/kWh</td>
            <td />
          </tr>
          <tr>
            <td>
              {t('co2CalculatorResult.heatingResult.totalHeatingDemand')}
              <Small>{t('co2CalculatorResult.heatingResult.totalHeatingDemandDescription')}</Small>
            </td>
            <td>{formatValue(heatingResultValues.total_heating_demand)} kWh/a</td>
            <td />
          </tr>
          <tr>
            <td>
              {t('co2CalculatorResult.heatingResult.primaryEnergyForHeating')}
              <Small>{t('co2CalculatorResult.heatingResult.primaryEnergyForHeatingDescription')}</Small>
            </td>
            <td>
              {showInput ? (
                <PlainNumberInput
                  name="primary_energy_for_heating"
                  ref={inputRef}
                  defaultValue={heatingResultValues.primary_energy_for_heating}
                />
              ) : (
                <span>{formatValue(heatingResultValues.primary_energy_for_heating)} kWh/a</span>
              )}
            </td>
            <td>
              {showInput ? (
                <div className={s.buttonsWrapper}>
                  <CircleButton onClick={() => setShowInput(false)}>
                    <Close />
                  </CircleButton>
                  <CircleButton
                    onClick={() =>
                      onSaveButtonClick({
                        callback: () => setShowInput(false),
                        fieldName: 'primary_energy_for_heating',
                        fieldValue: Number(inputRef.current.state.numAsString),
                      })
                    }
                  >
                    <TickIcon />
                  </CircleButton>
                </div>
              ) : (
                <CircleButton onClick={() => setShowInput(true)}>
                  <Pencil />
                </CircleButton>
              )}
            </td>
          </tr>
          <tr>
            <td>
              {t('co2CalculatorResult.heatingResult.heatingGhgEmission')}
              <Small>{t('co2CalculatorResult.heatingResult.heatingGhgEmissionDescription')}</Small>
            </td>
            <td>{formatValue(heatingResultValues.heating_ghg_emission)} kg/a</td>
            <td />
          </tr>
          {/* <tr>
            <td>
              {t('co2CalculatorResult.heatingResult.heatingGhgEquivalentCar')}
              <Small>{t('co2CalculatorResult.heatingResult.heatingGhgEquivalentCarDescription')}</Small>
            </td>
            <td>{formatValue(heatingResultValues.heating_ghg_equivalent_car)} km</td>
            <td />
          </tr>
          <tr>
            <td>
              {t('co2CalculatorResult.heatingResult.heatingGhgEquivalentPlane')}
              <Small>{t('co2CalculatorResult.heatingResult.heatingGhgEquivalentPlaneDescription')}</Small>
            </td>
            <td>
              {formatValue(heatingResultValues.heating_ghg_equivalent_plane)}
              <Small>{t('co2CalculatorResult.numberOfEconomyClassFlights')}</Small>
            </td>
            <td />
          </tr>
          <tr>
            <td>
              {t('co2CalculatorResult.heatingResult.heatingGhgEquivalentTrees')}
              <Small>{t('co2CalculatorResult.heatingResult.heatingGhgEquivalentTreesDescription')}</Small>
            </td>
            <td>
              {formatValue(heatingResultValues.heating_ghg_equivalent_trees)}
              <Small>{t('co2CalculatorResult.numberStoredTrees')}</Small>
            </td>
            <td />
          </tr> */}
          <tr>
            <td>
              {t('co2CalculatorResult.heatingResult.heatingCosts')}
              <Small>{t('co2CalculatorResult.heatingResult.heatingCostsDescription')}</Small>
            </td>
            <td>{formatCurrency(heatingResultValues.total_heating_costs)}</td>
            <td />
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default HeatingResult;
