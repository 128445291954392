/* eslint-disable no-console */

import config from 'config/config';
import { useCallback, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

interface IGTMPage {
  autoTrack?: boolean;
  title?: string;
  url?: string;
  includeProjectId?: boolean;
}

export const gtmPages: Record<string, IGTMPage> = {
  newProject: { title: 'New project', url: '/new-project', includeProjectId: false },
  objectDataForm: { title: 'Object data', url: '/object-data-form', includeProjectId: true },
  selectCalculationsForm: {
    title: 'Select calculations',
    url: '/select-calculations-form',
    includeProjectId: true,
  },
  co2Status: { title: 'CO2 status', url: '/co2-status', includeProjectId: true },
  generalInfoForm: { title: 'General info', url: '/general-info-form', includeProjectId: true },
  co2OptimizationForm: { title: 'CO2 optimization', url: '/co2-optimization-form', includeProjectId: true },
  result: { title: 'Result', url: '/result', includeProjectId: true },
};

export interface IGTMButton {
  id: string;
  label: string;
  action: string;
}

export const gtmButtons: Record<string, IGTMButton> = {
  createProject: { id: 'createProjectBtn', label: 'Create project button', action: 'submit' },
  objectDataStep: { id: 'objectDataStepBtn', label: 'Submit step button', action: 'submit' },
  selectCalculationsStep: { id: 'selectCalculationsStepBtn', label: 'Submit step button', action: 'submit' },
  co2StatusStep: { id: 'co2StatusStepBtn', label: 'Submit step button', action: 'submit' },
  generalInfoStep: { id: 'generalInfoStepBtn', label: 'Submit step button', action: 'submit' },
  co2Optimization: { id: 'co2OptimizationBtn', label: 'Submit step button', action: 'submit' },
  requestPdfTopBtn: { id: 'requestPdfTopBtn', label: 'Request PDF top button', action: 'download' },
  requestPdfBottomBtn: { id: 'requestPdfBottomBtn', label: 'Request PDF bottom button', action: 'download' },
  requestPdfModalBtn: { id: 'requestPdfModalBtn', label: 'Request PDF in modal window', action: 'download' },
};

export const trackButtonClick = ({ id, action, label, value }: IGTMButton & { value?: string }) => {
  window.dataLayer.push({
    event: 'buttonClick',
    eventProps: { category: 'buttons', action, label, id, value },
  });
};

export const gtmLinks = {
  termsOfUse: { id: 'termsOfUseUrl' },
  privacyPolicy: {
    id: 'privacyPolicyLink',
    label: 'Privacy policy link',
    action: 'click',
    value: config.privacyPolicyUrl,
  },
  fortyTwoWatt: { id: '42WattLink', label: '42Watt link', action: 'click', value: config.fortyTwoWebsiteUrl },
};

export const trackLinkClick = ({ action, label, value }: { action: string; label: string; value?: string }) => {
  window.dataLayer.push({
    event: 'linkClick',
    eventProps: { category: 'links', action, label, value },
  });
};

export const useTrackPageView = ({
  autoTrack = true,
  includeProjectId,
  title,
  url,
}: IGTMPage & { autoTrack?: boolean }) => {
  const location = useLocation();
  const { projectId } = useParams();
  const isRecorded = useRef(false);

  const trackPage = useCallback(() => {
    try {
      window?.dataLayer?.push({
        event: 'pageView',
        page: { url: `${url}${includeProjectId ? `/${projectId}` : ''}` || location.pathname, title },
      });
    } catch (error) {
      console.log(error);
    }
  }, [includeProjectId, location.pathname, projectId, title, url]);

  useEffect(() => {
    if (!isRecorded.current && autoTrack && title && url) {
      trackPage();
      isRecorded.current = true;
    }
  }, [autoTrack, isRecorded, title, trackPage, url]);

  return trackPage;
};
